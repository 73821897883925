/* Transactions.css */

/* Ensure the table container takes full width */
.transactions {
  padding: 0 100px;
  font-family: 'Karla', sans-serif;
  color: var(--text-color);
  margin: 0;
}

@media (max-width: 955px) {
  .transactions {
    padding: 0 20px;
  }
}

/* Container for the table with horizontal scrolling */
.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px; /* Added space above the table */
}

/* Ensure the table cells don't shrink below their content */
.ReactVirtualized__Table__rowColumn,
.ReactVirtualized__Table__headerColumn {
  flex-shrink: 0;
  white-space: nowrap;
  /* display: flex;
  align-items: center;
  justify-content: center; Center text in cells */
}

/* Styling for even and odd rows */
.evenRow {
  background-color: #ffffff;
}

.oddRow {
  background-color: #f7f7f7;
}

.ReactVirtualized__Table__row:hover {
  background-color: #eaeaea !important;
}

/* Styling for header cells */
.header-cell {
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
}

/* Remove outline on focus */
.ReactVirtualized__Grid {
  outline: none;
}

/* Center align specific columns */
.center-align {
  text-align: center;
}

/* Left align specific columns */
.left-align {
  text-align: left;
}


/* Style for the group header cells (first row) */
.group-header {
  border-right: 1px solid #ddd;
}

/* Remove the bottom border from the first header row cells */
.header-row:first-child .header-cell {
  border-bottom: none;
}

/* Adjust the borders for the merged header cells */
.header-cell:nth-child(2) {
  /* This targets the merged "Ticker" header cell */
  border-right: 1px solid #ddd;
}

