/* Footer.css */

/* Footer Styles */
.footer {
  background: var(--footer-bg);
  color: var(--text-color);
  padding: 20px 100px; /* Removed horizontal padding for alignment consistency */
  width: 100%;
  font-family: 'Karla', sans-serif;
}

/* Footer Container */
.footer-container {
  max-width: 100%; /* Ensure this matches other container max-widths */
  margin: 0 auto;
  /* padding: 0 16px; */
  display: flex;
  justify-content: space-between; /* Align links to left and © to right */
  align-items: center;
}

/* Footer Links */
.footer-links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 20px;
}

/* Footer Links List Items */
.footer-links li {
  margin: 0;
}

/* Footer Links */
.footer-links a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: var(--font-size-medium);
}

.footer-links a:hover {
  text-decoration: underline;
  color: var(--primary-hover);
}

/* Footer Copyright */
.footer p {
  margin: 0;
  font-size: var(--font-size-small);
}

/* Responsive styles for mobile */
@media (max-width: 955px) {
  .footer {
    padding: 20px 20px;
  }
  
  .footer-container {
    padding: 0 16px;
  }

}

/* Responsive styles for mobile */
@media (max-width: 600px) {
  .footer-container {
    flex-direction: column; /* Stack content vertically on small screens */
    align-items: center;
    text-align: center; /* Center text on mobile */
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .footer p {
    margin-top: 10px; /* Add spacing between sections on small screens */
    text-align: center; /* Center text on mobile */
    font-size: var(--font-size-small);
  }
}
