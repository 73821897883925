/* Dashboard.css */

/* Apply the same padding as the Home page */
.dashboard {
  padding: 0 100px;
  font-family: 'Karla', sans-serif;
  color: var(--text-color);
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 955px) {
  .dashboard {
    padding: 0 20px;
  }
}