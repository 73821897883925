/* Navbar.css */

/* Prevent background scrolling when menu is open */
.no-scroll {
  overflow: hidden;
}

/* Navbar container */
.navbar {
  background: var(--navbar-bg);
  color: var(--text-color);
  padding: 0 100px;
  height: 60px;
  display: flex;
  align-items: center;
  font-family: 'Karla', sans-serif;
  width: 100%;
  z-index: 1000;
}

/* Navbar logo */
.navbar-logo {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--text-color);
  text-decoration: none;
  cursor: pointer; 
}

/* Disable hover effects on .navbar-logo */
.navbar-logo:hover {
  color: var(--text-color);       /* Ensure the color doesn't change */
  text-decoration: none;          /* Ensure there's no underline */
  background: transparent;        /* Ensure background doesn't change */
}

/* Navbar menu */
.navbar-menu {
  list-style: none;
  display: flex; /* Ensure the menu displays on large screens */
  align-items: center;
  gap: 20px;
  margin: 0;
  padding: 0;
  margin-left: auto; /* Move menu items to the right */
}

/* Base styles for links */
.navbar-menu li a {
  text-decoration: none;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  transition: background 0.3s, color 0.3s;
  font-size: var(--font-size-medium);
}

/* Login Button */
.navbar-menu li .login-button {
  color: var(--primary-color);
  background: transparent;
  border: 2px solid var(--primary-color);
  transition: background 0.3s ease, color 0.3s ease;
}

.navbar-menu li .login-button:hover {
  background: linear-gradient(to bottom right, #0A84FF, #4FC3F7);
  color: #FFFFFF;
}

/* Get Started Button */
.navbar-menu li .get-started-button {
  color: #fff;
  background: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.navbar-menu li .get-started-button:hover {
  background: var(--primary-hover);
}

/* Logout Button */
.logout-button {
  background: none;
  border: none;
  color: var(--text-color);
  font-weight: 600;
  cursor: pointer;
  font-size: var(--font-size-medium);
}

/* Adjust hover effect for logout */
.logout-button:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

/* Hide hamburger menu on large screens */
.hamburger {
  display: none;
}






/* Responsive Styles */
@media (max-width: 955px) {
  .navbar {
    padding: 0 20px;
    position: relative; /* For positioning elements relative to the navbar */
  }

  /* Hamburger menu styles */
  .hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-left: auto;
    z-index: 1001; /* Ensure it's above other elements */
  }

  .hamburger div {
    width: 25px;
    height: 3px;
    background-color: var(--text-color);
    margin: 4px;
    transition: all 0.3s;
  }

  /* Hide hamburger when menu is open */
  .navbar.menu-open .hamburger {
    display: none;
  }

  /* Navbar menu styles */
  .navbar-menu {
    flex-direction: column;
    background: var(--background-gradient);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Cover the entire viewport */
    padding-top: 0; /* To offset the height of the navbar */
    margin: 0;
    z-index: 1000;
    transform: translateY(-100%); /* Hide the menu off-screen */
    transition: transform 0.5s ease-in-out; /* Smooth drop-down effect */
    justify-content: flex-start; /* Align menu items to the top */
  }

  /* Disable transition when menu is not open */
  .navbar-menu.notransition {
    transition: none;
  }

  .navbar.menu-open .navbar-menu {
    transform: translateY(0); /* Slide the menu into view */
  }

  .navbar-menu li {
    text-align: center;
    width: 100%;
    margin: 0 0; 
  }

  .navbar-menu li a,
  .navbar-menu li button {
    font-size: var(--font-size-large);
    color: var(--text-color);
    transition: color 0.3s;
    padding: 5px 20px;
  }

  .navbar-menu li a:hover,
  .navbar-menu li button:hover {
    color: var(--primary-color);
  }

  /* Close button styles */
  .close-button {
    display: none; /* Hide by default */
    font-size: var(--font-size-xlarge);
    font-weight: bold;
    color: var(--text-color);
    cursor: pointer;
  }

  /* Show close button when menu is open */
  .navbar.menu-open .close-button {
    display: block;
  } 

  /* Add or update the .menu-header class */
  .menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 60px;
    /* background: var(--background-gradient); */
    box-sizing: border-box;
    margin-top: 60px; /* Push .menu-header below the main navbar */
  }
  
  /* Ensure the menu-logo is styled correctly */
  .menu-logo {
    font-size: var(--font-size-large);
    font-weight: bold;
    color: var(--text-color);
    text-decoration: none;
    cursor: pointer; 
  }

  /* Make Login and Get Started buttons full width with side padding */
  .navbar-menu li .login-button,
  .navbar-menu li .get-started-button {
    width: 60%; /* Adjust the percentage as needed for left/right spacing */
    margin: 5px auto; /* Adds vertical spacing and centers the buttons */
    display: block; /* Ensures the buttons take up the full width */
    text-align: center; /* Centers the text within the buttons */
  }


  /* Make Login and Get Started buttons full width with side padding */
  .navbar-menu li button {
    width: 100%;
    padding: 0px 0px; /* Adds padding on top/bottom and left/right */
    margin: 0px 0; /* Adds vertical spacing between buttons */
    display: block; /* Ensures the elements take up the full width */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
    border-radius: 5px; /* Slightly rounded corners for a modern look */
    text-align: center; /* Centers the text within the buttons */
  }

  /* Ensure Get Started button has the same hover effect as on large screens */
  .navbar-menu li .get-started-button:hover {
    background: var(--primary-hover);
    color: #fff; /*  Maintain white text on hover */
  }

  /* Optional: Adjust transition for smoother hover effect */
  .navbar-menu li .get-started-button {
    transition: background 0.3s, color 0.3s;
  }  
}

/* Styles for large screens */
@media (min-width: 956px) {
  .hamburger,
  .close-button {
    display: none;
  }

  .navbar-menu {
    display: flex;
    flex-direction: row;
    position: static;
    height: auto;
    padding-top: 0;
    transform: none;
    background: transparent;
    margin-left: auto;
    align-items: center;
    transition: none;
  }

  .navbar-menu li {
    margin: 0 10px;
  }

  /* Hide menu-header on large screens */
  .menu-header {
    display: none;
  }

  /* Optional: Hide .menu-logo explicitly */
  .menu-logo {
    display: none;
  }

}


