/* Home.css */

/* Home Page Container */
.home {
  font-family: 'Karla', sans-serif;
  color: var(--text-color);
  padding: 0 100px; /* Adds padding on both sides */
  margin: 0;
}


/* Hero Section */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px; /* Adds space below navbar */
}

.hero-left {
  width: 60%;
  display: flex; /* Added Flexbox */
  flex-direction: column; /* Arrange items vertically */
  align-items: flex-start; /* Align items to the start */
}

.hero-left h1 {
  font-size: 3rem;
  line-height: 1.4;
  color: #4A1E94;
  font-weight: 400;
}

.hero-left .highlight {
  display: block;
  font-weight: 700;
  color: var(--primary-color);
}

.hero-left p {
  font-size: var(--font-size-large);
  margin: 20px 0;
}

.cta-button {
  background-color: var(--primary-color);
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2rem;
  margin-top: 10px; /* Adds spacing above the button */
  display: inline-block; /* Ensures margin-top takes effect */
}

.cta-button:hover {
  background-color: var(--primary-hover);
  color: #fff; /* Ensures text remains white on hover */
  border-color: var(--primary-hover); /* Changes border color on hover */
}

/* Right Side of the Hero */
.hero-right {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.hero-right .dotlottie-player {
  width: 400px;
  height: 400px;
}

/* Features Section */
.features {
  padding: 60px 0;
}

.feature-section {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

.feature-section:nth-child(even) {
  flex-direction: row-reverse;
}

.feature-text {
  flex: 1;
}

.feature-text h2 {
  font-size: var(--font-size-large);
  color: var(--primary-color);
  margin-bottom: 20px;
}

.feature-text p {
  font-size: var(--font-size-medium);
}

.feature-animation {
  flex: 1;
}

.feature-animation img {
  width: 100%;
  border-radius: 10px;
}

/* Responsive Styles */
@media (max-width: 955px) {
  .home {
    padding: 0 20px;
  }

  .hero {
    flex-direction: column;
    text-align: center;
  }

  .hero-left, .hero-right {
    width: 100%;
    display: flex; /* Ensure Flexbox is applied on small screens */
    flex-direction: column; /* Arrange items vertically */
    align-items: center; /* Center items horizontally */
  }

  /* Center the content inside hero-left */
  .hero-left {
    align-items: center;
  }  

  .dotlottie-player {
    width: 70%; /* Adjusting for mobile */
  }

  /* 1. Stack Feature Sections Vertically */
  .feature-section {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    margin-bottom: 40px; /* Adjust spacing between feature sections */
  }

  /* 2. Adjust Spacing Between Text and Animation */
  .feature-animation {
    margin-top: 20px; /* Adds space between text and animation */
  }

  /* 3. Make Animations Responsive */
  .feature-animation .dotlottie-player {
    width: 80%; /* Adjusts animation width for better responsiveness */
    height: auto; /* Maintains aspect ratio */
    max-width: 300px; /* Optional: Limits maximum width */
    margin: 0 auto; /* Centers the animation within its container */
  }

  /* 4. Center Feature Text (Optional) */
  .feature-text {
    text-align: center; /* Centers the text for better aesthetics */
  }

  /* Adjust Hero Right to center the animation */
  .hero-right {
    width: 100%;
    display: flex;
    justify-content: center; /* Centers the animation */
    margin-top: 20px; /* Adds space above the animation */
  }

  /* Optional: Adjust Hero Section for Consistency */
  .hero-right .dotlottie-player {
    width: 80%; /* Adjusts hero animation width on small screens */
    height: auto; /* Maintains aspect ratio */
    max-width: 300px; /* Optional: Limits maximum width */
    margin: 20px auto 0; /* Adds top margin and centers the animation */
  }

  /* Override flex-direction for even feature sections on small screens */
  .feature-section:nth-child(even) {
    flex-direction: column; /* Ensure even sections stack vertically */
  }
}


/* Testimonials Section */
.testimonials {
  text-align: center;
  padding: 60px 20px; /* Adding left/right padding to all major sections */
}

.testimonials h2 {
  font-size: var(--font-size-xxlarge);
  margin-bottom: 40px;
}

/* Swiper Styles */
.testimonials-swiper {
  width: 100%;
  padding-bottom: 40px; /* Space for pagination */
}

/* Customize Swiper navigation arrows */
.testimonials-swiper .swiper-button-prev,
.testimonials-swiper .swiper-button-next {
  color: var(--primary-color);
}

/* Customize Swiper pagination bullets */
.testimonials-swiper .swiper-pagination-bullet {
  background: var(--primary-color);
  opacity: 0.7;
}

.testimonials-swiper .swiper-pagination-bullet-active {
  background: var(--primary-hover);
  opacity: 1;
}

/* Adjust testimonial-item for both grid and carousel */
.testimonial-item {
  flex: 1 1 300px;
  max-width: 400px;
  margin: 20px auto; /* Center slides in carousel */
  background-color: var(--secondary-color);
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 100%; /* Ensure consistent height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial-item p {
  font-size: var(--font-size-medium);
  color: var(--light-text-color);
  margin-bottom: 20px;
}

.testimonial-item h4 {
  font-size: var(--font-size-medium);
  color: var(--text-color);
}

/* Remove grid layout on small screens */
@media (max-width: 768px) {
  .testimonials-grid {
    display: none; /* Hide grid on small screens */
  }
}

