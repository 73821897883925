/* App.css */

/* Global styles */
html, body, #root {
  height: 100%;
  font-family: 'Karla', sans-serif;
  background: var(--background-gradient);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

/* Global box-sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

/* General Container Styles */
.container {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 20px; /* Consistent inner padding */
}

/* Flex container for the App */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--background-gradient);
}

/* Main content area */
.App > :not(.navbar):not(.footer) {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  background-color: var(--secondary-color);
}

th, td {
  padding: 16px;
  text-align: left;
}

th {
  background-color: var(--primary-color);
  color: var(--button-text);
  font-weight: bold;
}

td {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

tr:hover td {
  background-color: #e0f0ff;
}

th:hover {
  background-color: var(--primary-hover);
}

