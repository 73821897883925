/* index.css */

/* Tailwind Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import 'Karla' font */
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap');

/* CSS Variables */
:root {
  /* Primary Colors */
  --primary-color: #0A84FF; /* Blue */
  --primary-hover: #005BB5; /* Darker Blue */

  /* Secondary Colors */
  --secondary-color: #F5F7FA; /* Light Gray */
  /* --background-gradient: linear-gradient(to right, #ffb3ba, #ffdfba); Example Gradient */
  --background-gradient: linear-gradient(135deg, #E0F7FA, #B0E0E6);

  /* Text Colors */
  --text-color: #333333; /* Dark Gray */
  --light-text-color: #666666; /* Medium Gray */

  /* Button Colors */
  --button-bg: var(--primary-color);
  --button-text: #ffffff;
  --button-hover-bg: var(--primary-hover);
  --button-hover-text: #ffffff;

  /* Background Colors */
  --footer-bg: transparent;
  --navbar-bg: transparent;
  --home-bg: transparent;

  /* Font Sizes */
  --font-size-small: 14px;
  --font-size-medium: 16px;
  --font-size-large: 1.2rem;
  --font-size-xlarge: 2rem;
  --font-size-xxlarge: 2.5em;
}

/* Global styles */
html, body, #root {
  height: 100%;
  font-family: 'Karla', sans-serif;
  background: var(--background-gradient);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

/* Global box-sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

/* General Container Styles */
.container {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 20px; /* Adds padding on the left and right sides */
}

/* Flex container for the App */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--background-gradient); /* Apply the gradient to the whole app */
}

/* Main content area */
.App > :not(.navbar):not(.footer) {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  background-color: var(--secondary-color);
}

th, td {
  padding: 16px;
  text-align: left;
}

th {
  background-color: var(--primary-color);
  color: var(--button-text);
  font-weight: bold;
}

td {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

tr:hover td {
  background-color: #e0f0ff;
}

th:hover {
  background-color: var(--primary-hover);
}

/* Reusable Button Styles */
.btn {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid transparent;
  border-radius: 5px;
  text-decoration: none;
  font-size: var(--font-size-medium);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.btn-primary {
  background-color: var(--button-bg);
  color: var(--button-text);
  border-color: var(--button-bg);
}

.btn-primary:hover {
  background-color: var(--button-hover-bg);
  color: var(--button-hover-text);
  border-color: var(--button-hover-bg);
}

.btn-secondary {
  background-color: transparent;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-secondary:hover {
  background-color: var(--primary-hover);
  color: var(--button-hover-text);
  border-color: var(--primary-hover);
}

/* Global link styles (optional, already handled above) */
a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: var(--primary-hover);
}
